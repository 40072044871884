// .pie {
//   --w: 40px;

//   width: var(--w);
//   height: var(--w);
//   aspect-ratio: 1;
//   position: relative;
//   display: inline-grid;
//   place-content: center;
//   margin: 5px;
//   font-size: 0.75rem;
//   font-weight: bold;
//   font-family: sans-serif;
//   background: transparent;
//   border-radius: 50%;
//   border: 3px solid rgba($color: #000000, $alpha: 0.12);

// }
// .pie:before {
//   content: "";
//   position: absolute;
//   border-radius: 50%;
//   inset: 0;
//   background: conic-gradient(var(--c) calc(var(--p)*1%),#0000 0);
//   -webkit-mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
//           mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
// }

@property --p {
  syntax: "<number>";
  inherits: true;
  initial-value: 0;
}

.pie {
  --p: 20;
  --b: 4px;
  --c: darkred;
  --w: 40px;

  width: var(--w);
  height: var(--w);
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  margin: 5px;
  place-content: center;
  font-size: 0.66125rem;
  font-weight: 500;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000000, $alpha: 0.1);
  border-radius: 50%;
}
.pie:before,
.pie:after {
  content: "";
  position: absolute;
  border-radius: 50%;
}
.pie:before {
  inset: 0;
  background: radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b)
      var(--b) no-repeat,
    conic-gradient(var(--c) calc(var(--p) * 1%), #0000 0);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
  mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
}
.pie:after {
  inset: calc(50% - var(--b) / 2);
  background: var(--c);
  transform: rotate(calc(var(--p) * 3.6deg))
    translateY(calc(50% - var(--w) / 2));
}
.animate {
  animation: p 1s 0.5s both;
}
.no-round:before {
  background-size: 0 0, auto;
}
.no-round:after {
  content: none;
}
@keyframes p {
  from {
    --p: 0;
  }
}
