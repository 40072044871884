.table-preview-wrapper {
  position: absolute;
  background-color: white;
  z-index: 999;
  left: -4.5rem;
  width: 120%;
  opacity: 0;
  margin-top: 2px;
  visibility: hidden;

  &.show-table-preview {
    visibility: visible;
    opacity: 1;
  }

  &__close-wrapper {
    position: absolute;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    border-radius: 100%;
    color: rgba(211, 47, 47, 0.9);
    top: -0.2rem;
    right: -0.4rem;
    z-index: 999;
    display: flex;
    padding: 0.2rem;
  }

  &__close {
    cursor: pointer;
    padding: 0;
    width: 17px !important;
    height: 17px !important;
  }
}
