@import "@/index.scss";

.c-dashboard-content {
  height: fit-content;

  .fs-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .box-and-graph-part {
    .kpi-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 15px;
    }
    .row-statuts-part {
      width: 100%;
      min-height: 130px;
      height: fit-content;
      display: flex;
      align-items: flex-start;
      gap: 20px;
    }
  }

  .row-evolution-part {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  .table-and-create-folder-part {
    height: fit-content;

    .recent-data-container {
      padding: 0px;
      position: relative;
    }
    .dashbord-table-part {
      height: fit-content;
      .dashbord-table-header {
        height: fit-content;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        b {
          color: var(--ui-dark-shade);
          font-size: var(--table-sub-title);
          // font-weight: 800;
        }

        a {
          color: var(--ui-infos);
          font-size: 13px;
        }
      }

      .dashbord-table-body {
        width: 100%;
      }
    }

    .create-folder-part {
      flex: 1;
      background-color: var(--ui-tertiary-light);
      height: 75%;
      margin-top: 10%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding: 10px;

      .icon-folder {
        font-size: 200px;
        color: var(--ui-secondary);
      }

      .body-create-folder {
        width: 254px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        button {
          font-size: 11px;
          width: 280px;
          height: 33px;
          // text-transform: inherit;
        }
      }
    }
  }
}
