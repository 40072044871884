@import "@/index.scss";

.document-body-container {
  width: 100%;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 10px;

  .MuiTable-root {
    min-width: 200px !important;
    flex: 1;
    .MuiTableBody-root {
      background-color: transparent;

      .MuiTableRow-root {
        background-color: transparent;

        .MuiTableCell-sizeMedium {
          padding: 6px 0px;
          border: none;
          background-color: transparent;
          font-size: 0.8rem;
          color: #070e3b;

          &:nth-child(2n + 1) {
            text-align: left;
            font-weight: 200;
            width: 50%;
          }

          &:nth-child(2n) {
            text-align: left;
            font-weight: 700;
          }
        }
      }
    }
  }

  .documentAddForm {
    // width: 420px;
    border: 1px dashed var(--ui-secondary);
    border-radius: 5px;
    padding: 5px;

    @include media-breakpoint-down(md) {
      width: 100%;
    }

    .upload-input {
      height: 31px;
      background-color: #e0e7ff;
      border: 1px dashed #bfceff;
      font-size: 11px;
      line-height: 11px;
      text-transform: initial;
      color: #5aa0dc;

      img {
        position: absolute;
        height: 35px;
        right: 2px;
        top: -15px;
      }

      &.file-uploaded {
        background-color: rgba($color: #006d77, $alpha: 0.15);
        border: 1px dashed var(--ui-primary);
        text-align: left;
        color: #000000;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 5px;
        height: fit-content;
        // line-height: 1rem;

        progress {
          opacity: 0;
        }

        .progress-element {
          width: 100%;
          margin: 0px !important;
        }

        .progress-container {
          position: relative;
          background: #eee;
          height: 7px;
          border-radius: 6px;
          overflow: hidden;
        }

        .progress-container::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 0;
          background: turquoise;
        }

        .progress-element--html .progress-container::before {
          animation: progress-html 1s ease-in forwards;
        }

        .progress-labelk {
          position: relative;
          margin-bottom: 3px;
        }

        @property --num {
          syntax: "<integer>";
          initial-value: 0;
          inherits: false;
        }

        // .progress-label::after {
        //   counter-reset: num var(--num);
        //   content: counter(num) "%";
        //   position: absolute;
        //   top: 0;
        //   right: 0;
        // }

        // .progress-element--html .progress-label::after {
        //   animation: progress-text-html 1s ease-in forwards;
        // }

        @keyframes progress-html {
          to {
            width: 100%;
          }
        }

        @keyframes progress-text-html {
          to {
            --num: 100;
          }
        }
      }
    }
  }

  .document-form-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    @include media-breakpoint-down(sm) {
    }

    .document-form {
      width: 0px !important;
      overflow: hidden;
      display: none;
    }

    .action-btns {
      width: 90px;
      padding: 0px;

      button {
        background-color: transparent;
        height: 30px;
        width: 30px;
        margin: 0px 7px;
        border-radius: 50%;
        border: none;
        transition: background 200ms ease-in-out;

        &:hover {
          background-color: rgba($color: #000000, $alpha: 0.07);
        }

        &:nth-child(1) {
          color: #5aa0dc;
        }
        &:nth-child(2) {
          color: #dc5a67;
        }
      }
    }
  }

  .modify-button {
    display: flex;
    flex: 1;
    // width: fit-content;
    align-items: center;
    justify-content: flex-end;
    padding: 0px !important;
    gap: 20px;

    .MuiButton-containedPrimary {
      margin: 0px;
      margin-top: -8px;
      width: fit-content;
      border-radius: 0;
    }
    .MuiButton-containedTertiary {
      margin: 0px;
      margin-top: -8px;
      width: fit-content;
      border-radius: 0;
      color: var(--ui-secondary-shade);
    }
  }
}
