.TableContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex: 1;

  .filterContainer {
    position: absolute;
    top: 5px;
    right: 0px;
    max-width: 500px;
    min-width: 275px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 7px;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    padding: 4px;
    padding-left: 10px;
    border-radius: 5px;
    z-index: 100;

    .searchIcon {
      .icon {
        color: rgba($color: #000000, $alpha: 0.3);
        font-size: 1.2rem;
      }
    }

    input {
      flex: 1;
      border: none;
      outline: none;
      padding: 2px;

      &::placeholder {
        font-size: 0.88rem;
        color: rgba($color: #000000, $alpha: 0.3);
      }
    }

    .searchClear {
      padding: 2px 6px;
      background-color: #e5e5e5;
      border-radius: 5px;
      border: none;
    }
  }

  .table {
    width: 100%;
    flex: 1;
  }
}
