@import "@/index.scss";

.folder-content {
  padding: 20px 0px;

  .folder-header {
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @include media-breakpoint-down(lg) {
      text-align: center;
    }

    @include media-breakpoint-down(md) {
    }

    @include media-breakpoint-down(sm) {
    }

    span {
      font-size: 1.8rem;
      font-weight: 700;

      @include media-breakpoint-down(lg) {
        font-size: 1.4rem;
      }
    }
    p {
      padding-top: 30px;
    }
  }

  .folder-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    padding: 30px 0px;

    @include media-breakpoint-down(lg) {
      flex-wrap: wrap;
    }

    .menu-item {
      // width: calc((100% / 6) - 2.9166%);
      width: 200px;
      aspect-ratio: 1;
      padding: 20px 10px 30px 10px;
      box-shadow: 0px 2px 50px rgba($color: #000000, $alpha: 0.2);
      border: 2px solid transparent;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 30px;
      cursor: pointer;
      transition: all 300ms ease-in-out;

      @include media-breakpoint-down(lg) {
        width: 200px;
      }
      @include media-breakpoint-down(md) {
        width: 220px;
      }
      @include media-breakpoint-down(sm) {
        width: 220px;
      }

      span {
        font-size: 0.98rem;
        font-weight: 700;
        text-align: center;
        color: var(--ui-tertiary-shade);
      }

      img {
        height: 100px;
      }

      &:hover {
        border: 2px solid var(--ui-primary);
      }

      &.desabled {
        opacity: 0.38;
        cursor: no-drop;
        border: none;

        &:hover {
          border: none;
        }
      }
    }
  }
}
