.form-wrapper {
  background-color: #fff;
}

.comptable-form {
  padding: 1.5rem;
  display: flex;
  gap: 2rem;
  width: 100%;
  align-items: center;

  label {
    margin-bottom: 1rem;
    font-size: 15px !important;
    font-weight: 600;
  }

  button {
    align-self: flex-end;
    margin-bottom: 1rem;
  }
  & > div {
    flex: 1;
    margin-bottom: 1rem;
  }
  input {
    width: 100%;

    white-space: nowrap;
  }

  input.bs-input-custom {
    font-size: 15px;
  }
}
