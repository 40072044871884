.document-viewer-container {
  width: 100%;
  height: fit-content;
  background-color: var(--ui-white);
  border-radius: 5px;
  overflow: hidden;

  .document-viewer-header {
    width: 100%;
    height: 50px;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba($color: #006d77, $alpha: 0.1);

    span {
      font-weight: 600;
      flex: 1;
      color: var(--ui-primary);
    }

    button {
      background-color: transparent;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      border: none;
      font-size: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--ui-primary);
      transition: background 200ms ease-in-out;

      &:hover {
        background-color: rgba($color: #006d77, $alpha: 0.1);
      }
    }
  }

  .document-viewer-body {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 130px 1fr;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    padding: 10px;

    .document-viewer-sidebar {
      // width: ;
      min-height: 340px;
      height: calc(100% - 50px);
      max-width: 600px;
      border: 1px dashed var(--ui-secondary);
      border-radius: 4px;
      display: flex;
      gap: 1rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 5px;

      .document-viewer-sidebar-item {
        width: 80px;
        height: 80px;
        // border: 1px solid ;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        cursor: pointer;

        position: relative;

        .overlay {
          opacity: 0;
          visibility: none;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 3;
          background-color: rgba(0, 0, 0, 0.3);
          transition: 0.3s ease-in-out;
        }

        &.active {
          .overlay {
            opacity: 1;
            visibility: visible;
          }
        }

        img {
          max-height: 100%;
          max-width: 100%;
          object-fit: cover;
        }
      }
    }

    .document-viewer-content {
      flex: 1;
      min-height: 340px;
      max-height: calc(100% - 50px) !important;
      height: calc(100% - 50px) !important;
      width: 100%;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 10px;

      .document-viewer-tools {
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        // border: 1px solid ;
        gap: 5px;

        button {
          height: 28px;
          width: 28px;
          border: none;
          border-radius: 3px;
          color: var(--ui-white);
          background-color: var(--ui-dark);
          display: flex;
          align-items: center;
          justify-content: center;

          &.full-screen-btn {
            background-color: transparent;
            color: var(--ui-dark);
            border: 1px solid var(--ui-dark);
            margin-left: 20px;
          }
        }
      }

      .document-viewer-square {
        height: 100%;
        max-height: 300px;
        min-height: 300px;
        width: 100%;
        border: 1px dashed var(--ui-secondary);
        overflow: auto;
        border-radius: 4px;
        padding: 5px;
        overflow: auto;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-height: 100%;
          max-width: 100%;
          height: 100%;
          transition: transform 0.1s linear;
        }

        .next-btns {
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translateX(-50%);
          display: none;

          gap: 5rem;

          button {
            height: 28px;
            width: 28px;
            border: none;
            border-radius: 3px;
            color: var(--ui-white);
            background-color: var(--ui-dark);
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 0px 17px rgba($color: #000000, $alpha: 0.15);
          }
        }
      }
    }
  }

  &.full-screen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;

    .document-viewer-square {
      max-height: 100% !important;
    }
  }
}
