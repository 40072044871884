@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@mixin auth-form-layout() {
  // width: 100%;
  background-color: #00000008;
  border-radius: 10px;
  box-shadow: 0px 5px 17px rgba($color: #000000, $alpha: 0.12);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include media-breakpoint-down(sm) {
    padding: 10px;
  }
}

@mixin vertical-display-layout($gap) {
  display: flex;
  flex-direction: column;
  gap: $gap;
}

@mixin text-ellipsis($width) {
  width: $width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin global-container() {
  position: relative;
  box-sizing: border-box;
  min-height: calc(100vh - 120px);
  height: auto;
  max-height: max-content;
  background: var(--ui-white);
  border-radius: 10px;
  box-shadow: 0px 0px 20px #eceeee;
  padding: 10px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  gap: 25px;

  .fs-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--ui-tertiary);

    .title-container {
      display: flex;
      align-items: center;
      gap: 10px;

      .fs-title {
        font-size: 18px;
        font-weight: 800;
        text-transform: uppercase;
        color: var(--ui-dark-shade);
        @include media-breakpoint-down(lg) {
          font-size: 12px;
        }
      }

      .icon {
        font-size: 26px;
        color: var(--ui-dark-shade);
      }
    }

    .breadscrumb {
      display: flex;
      // display: none;
      align-items: center;
      justify-content: center;
      gap: 3px;
      font-size: 13px;

      @include media-breakpoint-down(lg) {
        font-size: 10px;
      }
    }
  }

  .fs-content {
    position: relative;
    padding: 0px;
    height: fit-content;
  }

  @include media-breakpoint-down(lg) {
    // margin-left: unset;
    padding: 15px;
    margin-top: 33px;
    border-radius: 0;
  }

  @include media-breakpoint-down(xl) {
    padding: 15px;
    margin-top: 33px;
    border-radius: 0;
  }
}

@mixin forms-container() {
  padding: 10px;
  .bs-label-form,
  .bs-input-custom {
    font-size: 12px;
    sup {
      color: var(--ui-danger);
      font-size: 17px;
      top: 0;
      // font-size: large;
    }
  }

  .btn-manager {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    gap: 20px;
    button {
      width: auto;
      text-transform: inherit;
      height: 33px;
    }
  }
}

.pagination {
  margin-top: auto;
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 5px 0px 10px;
  border-top: 1px solid #eceeee;
  padding-top: 24px;
  // padding-bottom: 30px;

  nav {
    ul {
      li {
        .Mui-selected {
          background-color: var(--ui-primary);
          color: var(--ui-white);
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    height: fit-content;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }

  small {
    color: var(--ui-gray);
    margin-right: auto;
    font-size: 0.8rem;
    font-family: "Roboto Condensed", sans-serif;
  }

  button {
    height: 25px;
    width: 25px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    font-family: "Roboto Condensed", sans-serif;
  }
}

// Gestion des z-index des popup de tout l'application
.swal2-container {
  z-index: 9999 !important;
}
.css-zw3mfo-MuiModal-root-MuiDialog-root {
  z-index: 1021 !important;
}
.css-1u2w381-MuiModal-root-MuiDrawer-root {
  z-index: 1020 !important;
}

// Customisation du sweet alert 2 dans le projet
.swal2-popup {
  z-index: 9999 !important;
  max-width: 375px;
  padding-bottom: 10px;
  // position: relative;
  // display: flex;
  // justify-content: flex-start;
  // align-items: flex-start;

  // &::before{
  //   content: "";
  //   position: absolute;
  //   top: 10px;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   height: 100px;
  //   width: 94%;
  //   border-radius: 5px;
  //   background-color: rgba($color: #F5B544, $alpha: 0.18);
  // }

  .swal2-icon {
    width: 40px;
    height: 40px;
    border: 0.155rem solid;
    margin-top: 20px;

    .swal2-icon-content {
      font-size: 2rem;
      font-weight: 600;
    }
  }

  .swal2-title {
    font-size: 1.4rem;
  }

  .swal2-html-container {
    font-size: 0.88rem;
    font-weight: 500;
  }

  .swal2-actions {
    gap: 30px;
    button {
      font-size: 0.85rem;
      font-weight: 600;
    }
  }

  &.swal2-icon-warning {
    button.swal2-cancel {
      background-color: var(--ui-primary-tint) !important;
      color: var(--ui-dark-shade);
    }
    button.swal2-confirm {
      background-color: var(--ui-primary) !important;
    }
  }
}

// Tronquer un texte sur plusieurs lignes
@mixin line-clamp($lines, $line-height) {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  max-height: $lines * $line-height;
  text-overflow: ellipsis;
  overflow: hidden;
}

.MuiDataGrid-toolbarContainer {
  flex-direction: row !important;
  gap: 20px;
  margin-bottom: 10px;
  .css-15ni5ic-MuiButtonBase-root-MuiButton-root {
    width: fit-content;
  }
}

.css-1e7c2qr-MuiToolbar-root-MuiTablePagination-toolbar {
  p {
    margin-bottom: 0px;
  }
}

.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  line-height: 25px;
  height: 32px;
  width: inherit;
  outline: none;
}

// fix toolbar fiter column
.MuiDataGrid-toolbarContainer .MuiButtonBase-root {
  width: unset !important;
}

// fix pop up go behing create folder popup

.MuiDrawer-root {
  z-index: 3 !important;
}

// set styles for show hide icon
.input-with-show-password {
  position: relative;

  .toggle-show-password {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
