@import "@/index.scss";

.signin-container {
  @include auth-form-layout();
  gap: 30px;
  width: 55vw;
  overflow: auto;
  z-index: 10;

  @include media-breakpoint-up(lg) {
    background: url(../../../../assets/images/png/login_bg.png);
    background-position: center;
    // background-size: 120%;
    background-repeat: no-repeat;
    width: 55vw;
    height: 60vh;
  }
  @include media-breakpoint-down(lg) {
    width: 75vw;
    background: url(../../../../assets/images/png/login_bg.png);
    background-position: center;
    background-size: 300%;
    background-repeat: no-repeat;
    min-height: 60vh;
    height: fit-content;
  }
  @include media-breakpoint-down(md) {
    width: 80vw;
    height: 70vh;
    background: url(../../../../assets/images/png/login_bg.png);
    background-position: center;
    background-size: 300%;
    background-repeat: no-repeat;
  }
  @include media-breakpoint-down(sm) {
    width: calc(100% - 30px);
    height: fit-content;
    background: #ffffff;
  }

  .logo-part {
    padding: 30px;

    @include media-breakpoint-down(lg) {
      position: absolute;
    }

    @include media-breakpoint-down(sm) {
      width: calc(100% - 50px);
      display: flex;
      justify-content: center;
    }

    img {
      width: 120px;
      @include media-breakpoint-down(sm) {
        width: 90px;
      }
    }
  }

  .fs-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 45%;
    height: 100%;
    gap: 25px;
    padding: 2vh 2.5vw;

    @include media-breakpoint-down(lg) {
      width: 100%;
    }

    .return-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;

      @include media-breakpoint-down(sm) {
        display: none;
      }

      .return-button {
        width: fit-content;
        .icon-return {
          width: 30px;
          height: 30px;
          background-color: #ffffff;
          border: 1px solid #ddd;
          font-size: 0.3rem;
          border-radius: 50%;
          padding: 5px;
          // margin-right: 10px;
        }
      }
    }

    .header-part {
      width: 100%;

      @include media-breakpoint-down(lg) {
        width: 90%;
        margin: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
        margin-top: 100px;
      }

      h1.title {
        font-size: 1.6rem;
        margin-bottom: 20px;

        @include media-breakpoint-down(lg) {
          text-align: center;
        }

        @include media-breakpoint-down(sm) {
          font-size: 1.3rem;
        }
      }

      .subtitle-conatiner {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }

      p.subtitle {
        font-size: 1.1rem;
        font-weight: 700;

        @include media-breakpoint-down(sm) {
          font-size: 1rem;
        }
      }

      small {
        font-size: 0.9rem;
        font-weight: 400;
      }
    }

    .form-part {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 100%;

      @include media-breakpoint-down(lg) {
        width: 90%;
        margin: 0px auto 0px auto;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      .fs-row > div {
        padding-left: 0px;
        padding-right: 0px;
      }

      .remember-me {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-weight: 200;
        font-size: 0.9rem;
        color: #070e3b;

        .remember-me-text .MuiFormControlLabel-label {
          font-size: 0.9rem;

          @include media-breakpoint-down(sm) {
            font-size: 0.75rem;
          }
        }

        .link-primary {
          @include media-breakpoint-down(sm) {
            font-size: 0.75rem;
          }
        }
      }
    }

    .MuiButton-containedPrimary {
      border-radius: 0px;
      padding: 10px auto;
      /* margin-top: 20%; */
      width: 100%;
    }

    .footer-part {
      display: flex;
      justify-content: center;
      width: 100%;

      p {
        font-size: 0.9rem;
      }
    }
  }
}
