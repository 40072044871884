@import "@/index.scss";

.BoxConfrereInfoContent {
  height: fit-content;
  background-color: var(--ui-infos-shade);
  padding: 10px;
  width: calc((100% / 4) - 13.5px);
  min-width: 288px;
  min-height: 178px;
  max-height: 178px;
  box-shadow: 0px 1px 3px 3px #eceeee;
  border-radius: 5px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include media-breakpoint-down(md) {
    width: 100%;
  }

  .body-part {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .icon-part {
      width: 50px;
      display: flex;
      justify-content: center;
      padding-top: 10px;

      img {
        height: 30px;
        width: auto;
      }
    }

    .info-part {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 5px;

      b {
        width: 100%;
        @include line-clamp(1, 1.75em);
      }

      .description {
        font-size: 12px;
        text-align: justify;

        @include line-clamp(3, 1.75em);

        @include media-breakpoint-down(md) {
          font-size: 10px;
        }
      }

      .icon {
        font-size: 25px;
      }
    }
  }

  .footer-part {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0 0;
    Button {
      width: 100px;
      font-size: 12px;
      height: 30px;
      padding: 10px;
      text-transform: capitalize;

      svg {
        font-size: 12px;
      }
    }
  }

  &.active {
    border: 2px solid var(--ui-primary);
    background-color: rgba($color: #42738a, $alpha: 0.5);
  }
}
