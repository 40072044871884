@import "@/index.scss";

.home-layout-container{
    width: 100%;
    height: 1080px;
    box-sizing: border-box;

    @include media-breakpoint-down(lg) {
        overflow-x: hidden;
    }


    .home-container{
        width: 100%;
        min-height: 100%;
        // background-color: aquamarine;
    }
}