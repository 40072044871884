@import '@/index.scss';

.update-password-container{
   display: flex;
   justify-content: center;
   align-items: center;
   height: 98%;
   gap: 30px;
   width: 100%;

   @include media-breakpoint-up(lg) {
       width: 100%;
   }
   @include media-breakpoint-down(lg) {
      width: 100%;
   }
   @include media-breakpoint-down(md) {
       width: 100%;
   }

   .fs-form-container{
      background-color: #00000008;
      border-radius: 10px;
      // box-shadow: 0px 5px 17px rgba($color: #000000, $alpha: 0.12);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 37%;
      max-width: 600px;
      min-width: 300px;
      height: 70%;
      min-height: 500px;
      gap: 25px;
      padding: 2vh 2.5vw;

      .return-container{
         display: flex;
         align-items: center;
         justify-content: flex-end;
         width: 100%;

         .return-button{
            width: fit-content;
            .icon-return{
               width: 30px;
               height: 30px;
               background-color: #ffffff;
               border: 1px solid #ddd;
               font-size: 0.3rem;
               border-radius: 50%;
               padding: 5px;
               // margin-right: 10px;
            }
         }
      }

      .header-part{
         width: 100%;

         h1.title{
            font-size: 1.6rem;
            margin-bottom: 20px;
         }

         .subtitle-conatiner{
            display: flex;
            flex-direction: column;
            gap: 5px;
         }

         p.subtitle{
            font-size: 1.1rem;
            font-weight: 700;
            margin: 0px;
         }

         small{
            font-size: 0.90rem;
            font-weight: 400;
         }
      }

      .form-part{
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         justify-content: center;
         width: 100%;

         .fs-row > div{
            padding-left: 0px;
            padding-right: 0px;
         }
      }

      .MuiButton-containedPrimary{
         border-radius: 0px;
         padding: 10px auto;
         /* margin-top: 20%; */
         width: 100%;
     }

      .footer-part{
         display: flex;
         justify-content: center;
         width: 100%;
         
         p{
            font-size: 0.90rem;
         }
      }
   }
}