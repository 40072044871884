.dialog-title {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: var(--ui-primary);
  padding: 10px 20px !important;
  color: #ffffff;

  button {
    width: 40px;
    height: 40px;
    color: #ffffff;
  }
}
.delete-row {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.container-dialog {
  // width: 40vw;
  padding-top: 10px;
  font-size: 0.85rem;

  .modify-button {
    display: flex;
    align-items: center;
    justify-content: center;

    Button {
      width: 200px;
      margin-top: 20px;
    }
  }
}
