@import "@/index.scss";

.folders-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin: 0px auto;
  border: none;

  @include media-breakpoint-up(lg) {
    max-width: 100%;
  }
  @include media-breakpoint-down(md) {
    width: 100%;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
  @media only screen and (max-width: 1200px) {
    width: 100%;
  }

  .folders-body {
    width: 100%;
    padding: 30px;
    background-color: #ffffff;
    border: 0.125rem dashed var(--ui-secondary);
    border-radius: 10px;
    min-height: calc(100vh - 350px);
    height: auto;

    @include media-breakpoint-down(sm) {
      padding: 10px 5px;
    }
    @include media-breakpoint-down(md) {
      padding: 10px 5px;
    }
    @media only screen and (max-width: 1200px) {
      padding: 10px 5px;
    }

    .folders-body-container {
      background-color: var(--ui-tertiary);
      padding: 25px;
      border-radius: 10px;

      @include media-breakpoint-down(sm) {
        padding: 10px;
      }

      .MuiTabPanel-root {
        padding: 0px;

        .document-body-container {
          width: 100%;
          padding: 15px;
          background-color: #ffffff;
          border-radius: 10px;

          .document-form-container {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 20px;
            width: 100%;

            @include media-breakpoint-down(md) {
              flex-direction: column;
            }

            @include media-breakpoint-down(sm) {
              flex-direction: column;
            }
            @media only screen and (max-width: 1200px) {
              flex-direction: column;
            }
            .MuiPaper-root {
              flex: 1;
              .MuiTable-root {
                min-width: 200px !important;
                width: 100%;
                .MuiTableBody-root {
                  background-color: transparent;

                  .MuiTableRow-root {
                    background-color: transparent;

                    .MuiTableCell-sizeMedium {
                      padding: 6px 0px;
                      border: none;
                      background-color: transparent;
                      font-size: 0.8rem;
                      color: #070e3b;

                      &:nth-child(2n + 1) {
                        text-align: left;
                        font-weight: 200;
                        width: 50%;
                      }

                      &:nth-child(2n) {
                        text-align: left;
                        font-weight: 700;
                      }
                    }
                  }
                }
              }
            }

            .document-form {
              width: 0px !important;
              overflow: hidden;
              display: none;
            }

            .isOpenForm {
              width: 420px;

              @include media-breakpoint-down(md) {
                width: 100%;
              }

              @include media-breakpoint-down(md) {
                width: 100%;
              }

              @include media-breakpoint-down(sm) {
                width: 100%;
              }
              @media only screen and (max-width: 1200px) {
                width: 100%;
              }

              .upload-input {
                height: 46px;
                background-color: #e0e7ff;
                border: 1px dashed #bfceff;
                font-size: 11px;
                text-transform: initial;
                color: #5aa0dc;

                img {
                  position: absolute;
                  height: 35px;
                  right: 2px;
                  top: -15px;
                }
              }
            }
          }
        }
      }

      .modify-button {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-end;
        padding: 10px 14px 0px 0px;
        gap: 20px;

        .MuiButton-containedPrimary {
          margin: 0px;
          width: fit-content;
          border-radius: 0;
        }
        .MuiButton-containedTertiary {
          margin: 0px;
          width: fit-content;
          border-radius: 0;
          color: var(--ui-secondary-shade);
        }
      }
    }
  }
}
