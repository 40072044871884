@import "@/index.scss";

.fs-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  @include media-breakpoint-down(lg) {
    flex-direction: column-reverse;
    gap: 20px;
  }

  .search-input {
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }

  button {
    @include media-breakpoint-down(md) {
      width: 100%;
    }

    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }

  .title-container {
    display: flex;
    align-items: center;
    gap: 10px;

    .fs-title {
        font-size: 18px;
        font-weight: 800;
        text-transform: uppercase;
        color: var(--ui-dark-shade);
        @include media-breakpoint-down(lg) {
           font-size: 16px;
        }
    }

    .icon {
        font-size: 26px;
        color: var(--ui-dark-shade);
    }
  }

  .breadscrumb {
      display: flex;
      // display: none;
      align-items: center;
      justify-content: center;
      gap: 3px;
      font-size: 13px;

      @include media-breakpoint-down(lg) {
          font-size: 12px;
      }
  }

  .header-tools{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 50px;

    @include media-breakpoint-down(md) {
      width: 100%;
      justify-content: space-between;
    }

    .close-part{
      height: 40px;
      width: 40px;

      button{
        width: 100%;
        height: 100%;
        color: var(--ui-dark-shade);
      }
    }
  }
}

