.container-deedofsale {
  width: 100%;

  button{
    width: fit-content;
    padding: 5px 20px;
    border-radius: 5px;
    height: 40px;

    &.details-container{
      background: #ffffff;
      box-shadow: none;
      color: var(--ui-primary);
      display: flex;
      gap: 10px;
      border: 1px solid var(--ui-primary);
      font-size: 0.75rem;

      .icon{
        font-size: 1.2rem;
      }

    }
  }

  .f-deedofsale {
    width: 100%;
    border: 0.125rem dashed var(--ui-secondary);
    border-radius: 10px;
    min-height: calc(100vh - 420px) !important;
    background-color: #ffffff;
    padding: 0px 20px;
  
    .app-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 0;
      position: relative;
      width: 100%;
  
      @media screen and (max-width: 1141px) {
        justify-content: space-between;
        width: 100%;
      }

      .app-header-content{
        width: fit-content;
        display: flex;
        align-items: center;
        gap: 10px;
      }
  
      .app-header__back {
        padding: 0 0.5rem;
        border-right: 1px solid #ddd;
  
        @media screen and (max-width: 1141px) {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }
  
      .app-header__center {
        padding: 0 1rem;
        width: fit-content;
  
        @media screen and (max-width: 1141px) {
          width: unset;
          margin-left: 35px;
        }
      }
  
      .app-header__action {
        width: fit-content;
        display: flex;
        align-items: center;
        gap: 30px;

        .zoom{
          display: flex;
          align-items: center;
          justify-content: flex-end;
    
          .btn-zoom{
            padding: 0px !important;
            width: 50px !important;
            font-size: 1.5rem;
          }
        }

        @media screen and (max-width: 1141px) {
          width: unset;
        }
  
        @media screen and (max-width: 875px) {
          display: none;
        }
      }
    }
  
    .app-editor {
      position: relative;
  
      @media screen and (max-width: 1141px) {
        display: flex;
        flex-direction: column-reverse;
      }
  
      a {
        color: #000;
      }
    }
  
    .app-actions {
      position: absolute;
      width: 400px;
      right: 0;
      top: 28px;
      padding-top: 1.5rem;
  
      @media screen and (max-width: 1141px) {
        position: relative;
        width: 100%;
        top: unset;
        padding-top: unset;
        padding-left: 20px;
      }
  
      h3 {
        font-size: 16px;
      }
    }
  
    .rw-toolbar {
      background-color: #f3f4ff;
      min-height: 50px;
      padding-left: 70px;
    }
  
    .rw-toolbar-custom {
      background-color: #f3f4ff;
      padding-left: 70px;
  
      @media screen and (max-width: 1141px) {
        padding-left: 20px;
      }
    }
  
    .rw-editor {
      padding-left: 10px;

  
      @media screen and (max-width: 1141px) {
        padding-left: 20px;
        margin-right: 15px;
      }
    }
  
    .rdw-editor-main {
      min-height: 275px;
    }
  
    &.full-screen{
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
    }
  }

  .app-bottom {
    // display: none;
    margin: 10px;
    width: 300px;

    @media screen and (max-width: 875px) {
      display: flex;
      margin: 0.5rem;
      justify-content: center;
    }
  }
  
}


