@import "@/index.scss";

.ct-dossier {
  height: calc(100% - 100px);
  width: 100%;
  // padding: 0px 20px;
  position: relative;

  @include media-breakpoint-down(lg) {
    height: calc(100% - 200px);
  }

  @include media-breakpoint-down(lg) {
    padding: 0px;
  }
}
.SwipeableDrawer-panel-container {
  .MuiPaper-root {
    width: calc(100% - 280px);
    padding: 1rem 1.5rem;

    @include media-breakpoint-down(md) {
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    @media only screen and (max-width: 1200px) {
      width: 100%;
    }
  }
}
