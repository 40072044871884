@import "@/index.scss";

.ct-communaute {
  height: calc(100% - 100px);
  width: 100%;
  // padding: 0px 20px;
  position: relative;

  @include media-breakpoint-down(lg) {
    height: calc(100% - 200px);
  }

  @include media-breakpoint-down(lg) {
    padding: 0px;
  }

  .fs-container {
    @include global-container();

    .confrere-content,
    .partenaire-content {
      padding-top: 0px;

      .alert-info {
        background-color: var(--ui-primary-tint);
        margin-top: 20px;
      }

      .confrere-header,
      .partenaire-header {
        display: flex;
        justify-content: space-between;
        margin: 25px 0;
        align-items: center;

        @include media-breakpoint-down(lg) {
          flex-direction: column-reverse;
          gap: 20px;
        }

        .search-input {
          width: 380px;
          margin-bottom: 0;

          @include media-breakpoint-down(lg) {
            width: 100%;
          }
        }

        button {
          width: 200px;
          text-transform: inherit;
          height: 33px;

          @include media-breakpoint-down(md) {
            width: 100%;
          }

          @include media-breakpoint-down(lg) {
            width: 100%;
          }
        }
      }

      .confrere-body,
      .partenaire-body {
        display: flex;
        flex-wrap: wrap;
        gap: 18px;
        justify-content: space-evenly;
      }

      .confrere-body {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 18px;
        width: 100%;

        .liste-confrere{
            position: relative;
            width: 100%;
            // width: 64vw;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
            gap: 18px;
            // justify-content: space-evenly;
            transition: all 200ms ease-in-out;
        }
      }
    }
  }
}

.detail-container{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
  padding: 20px;
  position: absolute;
  right: 0px;
  width: 100%;
  height: 64.7vh;
  background-color: #ffffff;
  max-height: 64.7vh;
  min-height: 64.7vh;

  .detail-logo{
      display: block;
      width: 75px;
      height: 75px;
      // background-color: #A99A77;
      img{
        width: 100%;
      }
  }

  .detail-body{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 15px;
      flex: 1;

      .detail-header{
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          span{
              font-size: 0.88rem;
          }
          .detail-title{
              font-size: 1rem;
              font-weight: 800;
              margin-bottom: 10px;
          }
      }

      .detail-member{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 10px;
          width: 100%;

          span{
              font-size: 0.85rem;
              font-weight: 700;
          }

          .member-item{
              display: flex;
              justify-content: flex;
              align-items: center;
              gap: 20px;
              width: 100%;
              border-radius: 8px;
              background-color: #F1ECE1B3;
              padding: 5px 10px;

              img{
                  width: 40px;
                  height: 40px;
              }

              & > div{
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
              }
          }
      }

      .files-number{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 20px;

          span{
              font-size: 0.85rem;
              font-weight: 700;
          }

          & > div{
              height: 35px;
              width: 35px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: var(--ui-primary);
              color: #ffffff;
          }
      }

      .localisation{
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          gap: 5px;

          span{
              font-size: 0.85rem;
              font-weight: 700;
          }

          small{
              font-size: 0.70rem;
              font-weight: 500;
          }
      }
  }
}