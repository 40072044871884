@import '@/index.scss';

.signup-container{
    @include auth-form-layout();

   // height: 550px;
   height: 70vh;
   gap: 2.5vw;
   width: 55vw;
   overflow: auto;
   background: url(../../../../assets/images/png/login_bg.png);
   background-position: -200px center;
   background-size: 130%;
   background-repeat: no-repeat;

    @include media-breakpoint-down(lg) {
        width: 70vw;
    }
    @include media-breakpoint-down(md) {
        width: 70vw;
    }
    @include media-breakpoint-down(sm) {
        width: calc( 100% - 20px);
    }
    
   .signup-img{
      width: 57%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: url(../../../../assets/images/png/signup_bg.png);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
   }

   .fs-form-container{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 45%;
      height: 100%;
      gap: 20px;
      padding: 20px 2.5vw 20px 0px;

      .return-container{
         display: flex;
         align-items: center;
         justify-content: flex-end;
         width: 100%;

         .return-button{
            width: fit-content;
            .icon-return{
               width: 30px;
               height: 30px;
               background-color: #ffffff;
               border: 1px solid #ddd;
               font-size: 0.3rem;
               border-radius: 50%;
               padding: 5px;
               // margin-right: 10px;
            }
         }
      }

      .acces-offres{
         position: relative;
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
         overflow: hidden;
         // background-image: url(../../../../assets/images/gif/confetis_not_bg.gif);
         // background-position: center;
         // background-repeat: no-repeat;
         // background-size: cover;

         .header-part{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 15px;

            h1.title{
               font-size: 4rem;
               margin-bottom: 0px;
               text-align: center;
            }

            p.subtitle{
               font-size: 1.1rem;
               text-align: center;
            }

            small{
               text-align: center;
            }
         }

         .MuiButton-containedPrimary{
            border-radius: 0px;
            padding: 10px 4vw;
            margin-top: 30%; 
            width: fit-content;
         }

         img{
            position: absolute;
            width: 130%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
         }
      }

      .header-part{
         width: 100%;

         h1.title{
            font-size: 1.6rem;
            margin-bottom: 20px;
         }

         .subtitle-conatiner{
            display: flex;
            flex-direction: column;
            gap: 5px;
         }

         p.subtitle{
            font-size: 1.1rem;
            font-weight: 700;
            margin: 0px;
         }

         small{
            font-size: 0.90rem;
            font-weight: 400;
         }
      }

      .form-part{
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         justify-content: center;
         width: 100%;

         .MuiBox-root{
            .stepper-label{
               margin-bottom: 30px;
               font-size: 0.90rem;
   
               b{
                  color: var(--ui-primary);
               }
            }

            .MuiStepper-horizontal{
               padding: 0px 15%;
            }
         } 

         .stepper-body{
            padding: 20px 0px 0px 0px;

            .stepper-item{
               // padding-bottom: 20px;

               .fs-row > div{
                  padding-left: 0px;
                  padding-right: 0px;
               }
               
               .css-11xur9t-MuiPaper-root-MuiTableContainer-root{
                  background-color: transparent;
                  box-shadow: none;
                  font-family: 'Raleway', sans-serif !important;
               }

               .MuiTable-root{
                  .MuiTableBody-root{
                     background-color: transparent;
                     
                     .MuiTableRow-root{
                        background-color: transparent;

                        .MuiTableCell-sizeMedium{
                           padding: 6px 0px;
                           border: none;
                           background-color: transparent;
                           font-size: 0.80rem;
                           color: #070E3B;

                           &:nth-child(2n + 1){
                              text-align: left;
                              font-weight: 200;
                              width: 50%;
                           }

                           &:nth-child(2n){
                              text-align: left;
                              font-weight: 700;
                           }
                        }
                     }
                  }
               }
            }
         }
      }

      .MuiButton-containedPrimary{
         border-radius: 0px;
         padding: 10px 4vw;
         margin-top: 0px; 
         width: fit-content;

         &.fullbutton{
            width: 100%;
         }
      }
      .stepper-return.MuiButton-containedPrimary{
         border-radius: 0px;
         padding: 10px;
         margin-top: 0px; 
         width: fit-content;

         .icon-return{
            font-size: 2rem;
         }
      }

      .footer-part{
         display: flex;
         justify-content: center;
         align-items: flex-start;
         width: 100%;
         
         p{
            font-size: 0.90rem;
         }
      }
   }
}
