@import '@/index.scss';

.ct-subscription {
    height: calc( 100% - 100px);
    width: 100%;
    padding: 0px 20px;
    position: relative;

    @include media-breakpoint-down(lg){
        height: calc( 100% - 200px);
    }

    @include media-breakpoint-down(lg) {
        padding: 0px;
    }

}