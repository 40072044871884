.chart-evolution-container {
  background-color: var(--ui-tertiary-light);
  padding: 10px;
  border-radius: 5px;
  flex: 1;
  .header-chart-evolution {
    display: flex;
    justify-content: space-between;

    b {
      color: var(--ui-tertiary-shade);
      font-size: 15px;
      font-weight: 800;
    }
  }

  .body-chart-evolution {
    .highcharts-container {
      width: auto !important;
      height: auto !important;
      padding-top: 10px;

      .highcharts-root {
        height: 250px !important;
        margin-top: 10px;

        // .highcharts-background {
        //     width: 1000px;
        // }

        .highcharts-plot-background {
          left: 0px;
        }
        // width: 1300px;
      }
    }
  }
}
