@import '@/index.scss';

.c-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 280px;
  // background: var(--ui-medium);
  // background: transparent;
  display: block;
  z-index: 3;
  // border-right: 10px solid var(--ui-light);
  // box-shadow: 0px 0px 30px rgba(var(--ui-medium-rgb), 0.6);
  --sidebar-top-height: 270px;

  // @include media-breakpoint-down(lg) { 
  //   &.opened {
  //     display: block;
  //   }
  // }
  @media only screen and (max-width: 1200px) {
    display: none !important; 

    &.opened {
      display: block !important;
      padding-right: 10px;
      background-color: var(--ui-white);
      margin-top: 0px;
      min-height: 350px;
      height: fit-content;
      border-radius: 0px 0px 10px 0px;
    }
  }

  @include media-breakpoint-down(lg) { 
    &.opened {
      display: block;
    }
  }

  @include media-breakpoint-up(lg) {
      display: block;
  } 



  // .sidebar-top {
  //   padding: 20px;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: flex-start;
  //   align-items: center;
  //   position: relative;
  //   height: var(--sidebar-top-height);

  //   &__header-btn-container {
  //     height: 50px;
  //     width: 100%;
  //     // background: rgba(var(--ui-dark-rgb), 0.3);
  //     padding: 10px;
  //     border-radius: 3px;
  //     margin-bottom: 20px;
  //     display: flex;
  //     align-items: center;
  //     justify-content: flex-start;
  //     gap: 20px;

  //     @include media-breakpoint-up(lg) { 
  //         display: none;
  //     }

  //     .rounded-square{
  //         height: 35px;
  //         width: 35px;
  //         border-radius: 10px;
  //         // background: var(--ui-secondary);
  //         display: flex;
  //         align-items: center;
  //         justify-content: center;
  //         transition: all 200ms ease-in-out;

  //         &:hover {
  //           cursor: pointer;
  //           background: rgba(var(--ui-secondary-rgb),0.5);
  //         }

  //         &:nth-child(2){
  //           margin-left: auto;
  //         }
  //     }
  //   }

  //   &__logo-part {

  //     width: 100%;
  //     display: flex;
  //     justify-content: center;
  //     position: relative;
  //     background: rgba(var(--ui-dark-rgb), 0.3);
  //     padding: 10px 0px;
  //     border-radius: 3px;
  //     margin-bottom: 40px;

  //       @include media-breakpoint-down(lg) { 
  //         display: none;
  //       }

  //       &::after{
  //         position: absolute;
  //         content: '';
  //         bottom: -15px;
  //         left: 50%;
  //         transform: translateX(-50%);
  //         width: 50px;
  //         height: 5px;
  //         border-radius: 3px;
  //         background: var(--ui-secondary);
  //       }

  //       &__logo {
  //         height: 30px;
  //         width: auto;
  //       }
  //   }


  //   &__avatar {
  //     margin-bottom: 20px;
  //     height: 50px;
  //     width: 50px;
  //     border-radius: 50%;
  //   }

  //   &__username {
  //     font-weight: 500;
  //     font-size: 14px;
  //     line-height: 16px;
  //     text-align: center;
  //     letter-spacing: 1px;
  //     font-variant: small-caps;
  //     color: var(--ui-secondary);
  //     mix-blend-mode: normal;
  //   }

  //   &__identity_number {
  //     font-weight: 300;
  //     font-size: 10px;
  //     line-height: 26px;
  //     text-align: center;
  //     letter-spacing: 1px;
  //     font-variant: small-caps;
  //     color: var(--ui-secondary);
  //     margin-bottom: 10px;
  //   }

  //   &__other-infos {
  //     width: 100%;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     margin-bottom: 20px;
  //     gap: 40px;

  //     &__infos-bloc {
  //       display: flex;
  //       align-items: center;
  //       color: var(--ui-secondary);
  //       font-size: var(--subtitle-size);
  //       span{
  //         max-width: 100px;
  //         white-space: nowrap;
  //         overflow: hidden;
  //         text-overflow: ellipsis;
  //         margin-left: 10px;
  //       }
  //     }
  //   }





  // }

  .sidebar-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin-top: 85px;
    overflow: auto;
    padding: 10px 0;
    padding-left: 0px;
    height: calc(100% - var(--sidebar-top-height));

    @media only screen and (max-width: 1200px) {
      height: 100%;
      margin-top: 25px;
    }

    @include media-breakpoint-down(lg) { 
      padding-right: 10px;
      background-color: var(--ui-white);
      margin-top: 0px;
      min-height: 350px;
      height: fit-content;
      border-radius: 0px 0px 10px 0px;
    }

  }
}
