@import "@/index.scss";

.form-utilisateur {
  @include forms-container();
  .row {
    width: 100%;
    margin-left: 0;

    & > * {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
