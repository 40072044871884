@import "@/index.scss";

.select-container{
    margin: 0px 0px 10px;
    padding: 0px 10px;
    header{
      font-size: 1rem;
    }
    label{
      font-size: 0.86rem;
    }
  }