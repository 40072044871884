@import "@/index.scss";

.select-container {
  padding-top: 20px;
  position: relative;
  border: 1px dashed var(--ui-secondary);
  border-radius: 10px;
  margin: 30px 0px;
  padding: 0px 20px;
  // box-shadow: 0px 2px 50px rgba($color: #000000, $alpha: 0.2);
  header {
    position: relative;
    margin-top: -20px;
    margin-bottom: 10px;
    padding: 5px 20px;
    background-color: #ffffff;
    width: fit-content;
    font-size: 1.1rem;
    border-radius: 5px;
    // border: 1px dashed var(--ui-secondary);
    // &::before{
    //   content: "";
    //   position: absolute;
    //   bottom: 0px;
    //   left: 50%;
    //   transform: translate(-50%, 5px);
    //   width: calc(100% + 10px);
    //   height: calc(50% + 5px);
    //   background-color: #ffffff;
    //   z-index: 1;
    // }
    // span{
    //   z-index: 2;
    //   position: relative;
    // }
  }
  .fs-row {
    label {
      font-size: 0.98rem;
      font-weight: 600;
    }
    select,
    input {
      font-size: 0.98rem;
      margin-top: 10px;
    }
  }

  // .info-etude {
  //   display: flex;
  //   flex-direction: row;
  // }
}

.c-list-buyer-seller {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 200px);
  background-color: rgba($color: #000000, $alpha: 0.04);
  padding: 0px 20px 20px 20px;
  border-radius: 10px;

  .fs-title-container {
    display: flex;
    align-items: center;
    gap: 15px;

    .fs-title {
      font-size: 18px;
      font-weight: 800;
      text-transform: uppercase;
      color: var(--ui-dark-shade);
      @include media-breakpoint-down(lg) {
        font-size: 12px;
      }
    }

    .title-tags {
      font-size: 17px;
      font-weight: 700;
      text-transform: uppercase;
      color: var(--ui-primary);
    }
  }

  .folder-item-content {
    padding: 20px 0px;
    width: 100%;
    .folder-item-header {
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      // align-items: center;
      gap: 2vw;

      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }

      @include media-breakpoint-down(md) {
      }

      @include media-breakpoint-down(sm) {
      }

      .header-title-container {
        width: calc(100% / 3);
        min-width: 340px;

        @include media-breakpoint-down(lg) {
          width: 100%;
          text-align: center;
        }

        span {
          font-size: 1.25rem;
          font-weight: 800;
        }
        p {
          padding-top: 5px;
          font-size: 0.8rem;
        }
      }

      .group-button-container {
        display: flex;
        flex-wrap: wrap;

        align-items: center;
        flex: 1;
        gap: 15px 25px;

        @include media-breakpoint-down(lg) {
          width: 100%;
          text-align: center;
          flex-wrap: wrap;
        }

        @include media-breakpoint-down(md) {
          gap: 10px;
        }

        @include media-breakpoint-down(sm) {
          flex-wrap: wrap;
          justify-content: center;
        }

        button {
          position: relative;
          border: none;
          background-color: #ffffff;
          font-size: 0.84rem;
          font-weight: 700;
          box-shadow: 0px 5px 12px rgba($color: #000000, $alpha: 0.1);
          padding: 9px 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          white-space: nowrap;

          @include media-breakpoint-down(md) {
            font-size: 0.78rem;
            padding: 9px 10px;
          }

          .icon {
            font-size: 1.1rem;
          }
          &.active {
            background-color: var(--ui-secondary-light);
            color: #ffffff;
          }

          &.green-button {
            background-color: var(--ui-primary);
            color: #ffffff;
          }

          .fs-badge {
            position: absolute;
            top: -10px;
            right: -10px;
            width: fit-content;
            min-width: 20px;
            height: 20px;
            background-color: #0d8cca;
            color: var(--ui-white);
            font-size: 0.76rem;
            font-weight: 600;
            padding: 0px 6px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "Roboto Condensed", sans-serif;
            box-shadow: 0px 5px 12px rgba($color: #000000, $alpha: 0.1);
          }
        }
      }
    }

    .folder-item-body {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      gap: 20px;
      padding: 10px 0px 30px 0px;

      @include media-breakpoint-down(lg) {
        flex-wrap: wrap;
      }

      @include media-breakpoint-down(md) {
        gap: 15px;
      }

      @include media-breakpoint-down(sm) {
        gap: 15px;
        flex-direction: column-reverse;
        justify-content: center;
      }

      .menu-item {
        // width: 200px;
        width: calc((100% / 4.2) - 14.29px);
        // aspect-ratio: 1;
        padding: 20px 10px 20px 10px;
        box-shadow: 0px 2px 50px rgba($color: #000000, $alpha: 0.2);
        border: 2px solid transparent;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        cursor: pointer;
        // transform: translate(100px, 0px);
        animation: fadeIn 200ms ease-in-out;

        @keyframes fadeIn {
          from {
            transform: translate(100px, 0px);
            opacity: 0;
          }
          to {
            transform: translate(0px, 0px);
            opacity: 1;
          }
        }

        span {
          font-size: 0.8rem;
          font-weight: 700;
          text-align: center;
          color: var(--ui-tertiary-shade);
        }

        img {
          height: 50px;
        }

        &:hover {
          border: 2px solid var(--ui-primary);
        }

        &.border-dashed {
          justify-content: space-between;
          border: 2px dashed var(--ui-secondary);
          width: 182px;
          height: 182px;

          .icon-add {
            font-size: 4.5rem;
            color: #000000;
          }

          &.desabled {
            cursor: no-drop;
          }
        }

        &.desabled {
          opacity: 0.38;
          cursor: no-drop;
          border: none;
          width: 168px;
          height: 168px;

          &:hover {
            border: none;
          }
        }

        &.details {
          align-items: flex-start;
          min-width: 290px !important;
          max-width: 330px !important;
          padding: 0px;
          gap: 5px;
          cursor: text;
          border: 2px solid var(--ui-primary);

          .situation-container {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;

            .situation {
              width: fit-content;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              background: rgba($color: #a99a77, $alpha: 0.4);
              padding: 5px 10px;
              margin: 0px;
              border-radius: 0px 10px 0px 10px;

              &.single {
                background: rgba($color: #5dc2ef, $alpha: 0.4);
                img {
                  filter: brightness(0) saturate(100%) invert(51%) sepia(94%)
                    saturate(1049%) hue-rotate(164deg) brightness(89%)
                    contrast(86%);
                }
                span {
                  color: #1e9fd6;
                }
              }
              &.maried {
                background: rgba($color: #ef7c5d, $alpha: 0.4);
                span {
                  color: #ef7c5d;
                }
              }
              &.divorced {
                background: rgba($color: #d8bc26, $alpha: 0.4);
                span {
                  color: #d8bc26;
                }
              }
              &.veuf {
                background: rgba($color: #000000, $alpha: 0.3);
                span {
                  color: #000000;
                }
              }
              .icon {
                margin-right: 5px;
                font-size: 1.15rem;
              }

              img {
                height: 22px;
                margin-right: 5px;
              }
            }
            &.entreprise .situation {
              width: fit-content;
              background: rgba($color: #7867da, $alpha: 0.4);

              .icon {
                margin-right: 5px;
                font-size: 1.3rem;
              }
            }
          }

          ul {
            list-style: none;
            margin: 0px;
            padding: 0px 15px;
            width: 100%;

            li {
              margin: 0px;
              display: flex;
              align-items: center;
              gap: 10px;
              justify-content: space-between;
              padding: 3px 0px;

              span {
                font-size: 0.75rem;

                &:nth-child(2) {
                  font-weight: 500;
                  flex: 1;
                  text-align: right;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }
            }
          }

          .file {
            padding: 0px 20px 10px 15px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .link-primary {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 5px;
              width: fit-content;
              color: var(--ui-primary) !important;

              .icon {
                font-size: 1.15rem;
              }

              span {
                color: var(--ui-primary);
              }
            }

            .actions {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              gap: 10px;
              flex: 1;
              button {
                height: 28px;
                width: 28px;
                border: none;
                border-radius: 14px;

                &.btn-add-doc {
                  height: 28px;
                  width: 28px;
                  border: none;
                  border-radius: 14px;
                  color: var(--ui-white);
                  background-color: var(--ui-primary);
                }

                &.btn-edited {
                  color: white;
                  background-color: rgba($color: #5cb85c, $alpha: 0.9);
                  &:hover {
                    background-color: rgba($color: #5cb85c, $alpha: 0.5);
                    color: var(--text-dark);
                  }
                }
                &.btn-deleted {
                  color: white;
                  background-color: rgba($color: #d32f2f, $alpha: 0.9);
                  &:hover {
                    background-color: rgba($color: #d32f2f, $alpha: 0.5);
                    color: var(--text-dark);
                  }
                }
                &.btn-detail {
                  color: var(--text-dark);
                  background-color: rgba($color: #818181, $alpha: 0.35);
                  &:hover {
                    background-color: rgba($color: #818181, $alpha: 0.5);
                    color: var(--text-dark);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .modify-button {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;

    @include media-breakpoint-down(sm) {
      justify-content: center;
    }

    .MuiButton-containedPrimary {
      margin: 0px;
      width: fit-content;
      border-radius: 0;
    }
  }

  .fs-modal-bg {
    padding: 0px !important;
    width: fit-content !important;
  }

  &.desabled {
    button,
    .menu-item {
      cursor: no-drop;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #ffffff, $alpha: 0.8);
      border-radius: 10px;
    }
  }
}

.fs-modal-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--ui-primary);
  height: 50px;
  border: none;

  .fs-modal-title {
    font-size: 1.3rem;
    font-weight: 600;
    color: #ffffff;
    text-transform: uppercase;

    b {
      color: var(--ui-warning);
      font-weight: 500;
    }

    em {
      font-weight: 300;
      font-style: normal;
    }

    @include media-breakpoint-down(lg) {
      font-size: 1.3rem;
    }

    @include media-breakpoint-down(md) {
      font-size: 1.2rem;
    }

    @include media-breakpoint-down(sm) {
      font-size: 1rem;
    }
  }

  button.fs-modal-close-button {
    position: absolute;
    right: 0;
    height: 100%;
    width: 50px;
    border: none;
    background-color: transparent;

    .icon {
      font-size: 2rem;
      color: #ffffff;
    }
  }
}

.buyerSeller-modal {
  .css-1idl8k2-MuiPaper-root-MuiDialog-paper {
    min-width: 675px;
  }
  #alert-dialog-description {
    min-width: 40vw !important;
  }

  .new-folder-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 30px;
    gap: 50px;
    position: relative;

    .background-opacity {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #ffffff;
      opacity: 0.75;
      overflow: hidden;
      padding: 0px;
      margin: 0px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        min-height: 100%;
        width: 105%;
        opacity: 0.18;

        @include media-breakpoint-down(md) {
          height: 113%;
          width: auto;
        }

        @include media-breakpoint-down(sm) {
          font-size: 1rem;
        }
      }
    }

    .new-folder-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 1;

      span {
        font-size: 1.25rem;
        font-weight: 800;
      }
      p {
        padding-top: 5px;
        font-size: 0.9rem;
      }
    }

    .type-vendeur-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 4rem;
      padding-bottom: 30px;
      z-index: 1;

      @include media-breakpoint-down(sm) {
        gap: 2rem;
      }

      .type-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 30px;
        width: 175px;
        height: 175px;
        background-color: rgba($color: #006d77, $alpha: 0.05);
        border-radius: 12px;
        padding: 20px;
        cursor: pointer;
        img {
          height: 50%;
        }
        span {
          font-size: 0.9rem;
          font-weight: 700;
          color: var(--ui-primary);
        }
      }
    }
  }

  .MuiDialog-scrollPaper {
    .MuiDialog-paperScrollPaper {
      @include media-breakpoint-down(lg) {
        max-width: none !important;
        width: 93%;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        margin: 0px 15px;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
        margin: 0px 5px;
      }
    }
  }

  &.acte-vente {
    .css-1idl8k2-MuiPaper-root-MuiDialog-paper {
      min-width: 60%;
    }
  }
}
