.content-block-stat-folder {
  height: fit-content;
  width: 100%;
  background-color: var(--ui-primary);
  color: var(--ui-white);
  //   padding: 10px;
  display: flex;
  flex-direction: column;

  &.inactif {
    // height: 170px;
    // background-color: var(--ui-medium);
    opacity: 0.3;
    cursor: not-allowed;
  }

  .block-stat-by-folder-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    .icon {
      font-size: 2.5rem;
    }
  }

  .block-stat-folder-body {
    width: 100%;
    height: 55px;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    b {
      font-size: 2.8rem;
    }
  }

  .block-stat-folder-footer {
    // margin: 10px auto;
    background-color: var(--ui-primary-shade);
    height: fit-content;
    width: 100%;
    padding: 5px;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    .stat {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      border-right: 1px solid rgba($color: #ffffff, $alpha: 0.3);
      padding-right: 5px;

      &:last-child {
        border: none;
        padding: 0px;
      }

      .stat-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // flex-direction: column;
        gap: 5px;
        span {
          font-size: 10.5125px;
        }

        b {
          font-size: 13.5125px;
        }
      }
    }
  }
}
