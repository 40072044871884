@import "@/index.scss";

.c-modal {

    width: 500px ;
    padding: 20px ;
    border-radius: 20px ;
    background: var(--ui-secondary) ;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed ;
    top: 50% ;
    left: 50% ;
    transform: translate(-50%,-50%) !important;

    @include media-breakpoint-down(md){
        width: 95%;
    }

    .close-icon {
        position: absolute;
        top: 0px;
        right: 0px;
    }

    .modal-avatar {
        margin: 20px 0px;
        height: 50px;
        width: 50px;
        border-radius: 50%;
    }

    p {
        text-align: center ;
        font-weight: 200 ;
        margin-bottom: 30px;

        b{
            color: var(--ui-primary);

            &.wha{
                color: var(--ui-dark);
            }
        }
    }

    .btn-modal {
            width: 50%;
            border-radius: 30px ;
    }

}