.chip-item {
  height: 27px;
  font-weight: 600;

  &.encours {
    background-color: rgba($color: #f5b544, $alpha: 0.3);
    color: var(--ui-warning);
  }
  &.cloture {
    background-color: rgba($color: #006d77, $alpha: 0.3);
    color: var(--ui-primary);
  }
  &.annule {
    background-color: rgba($color: #e63838, $alpha: 0.3);
    color: var(--ui-danger);
  }

  &.brouillon {
    background-color: rgba($color: #afafaf, $alpha: 0.3);
    color: #444444;
  }

  &.suspendu {
    background-color: rgba($color: #9b5de6, $alpha: 0.3);
    color: #9b5de6;
  }

  &.valide {
    background-color: rgba($color: #006d77, $alpha: 0.3);
    color: var(--ui-primary);
  }
}

.dropdown-icon {
  font-size: 1.3rem;
}

.deleted-link {
  color: var(--ui-danger) !important;
  .dropdown-icon {
    color: var(--ui-danger) !important;
  }
}

.history-popup {
  .css-lzee2o-MuiPaper-root-MuiDialog-paper {
    width: 50%;
    max-width: 100%;
    max-width: 500%;
    min-height: 500px;
  }
}
