@import "@/index.scss";

.ct-administration {
  height: calc(100% - 100px);
  width: 100%;
  // padding: 0px 20px;
  position: relative;

  @include media-breakpoint-down(lg) {
    height: calc(100% - 200px);
  }

  @include media-breakpoint-down(lg) {
    padding: 0px;
  }

  .fs-container {
    @include global-container();
  }

  .admin-tabs{
    // .setting-tabs{
    //   margin-top: 10px;
    //   border-bottom: 1px solid var(--ui-light-shade);
    //   .Mui-disabled{
    //     display: none;
    //   }
    //   .MuiTabs-hideScrollbar{
    //     .MuiTabs-flexContainer{
    //       .MuiButtonBase-root{
    //         text-transform: initial;
    //         &.Mui-selected{
    //           background-color: rgba($color: #006D77, $alpha: 0.70);
    //           color: var(--ui-white);
    //           font-weight: 600;
    //         }
    //       }
    //     }
    //     .MuiTabs-indicator {
    //       height: 3px;
    //     }
    //   }
    // }
    #vertical-tabpanel-0{
      border: 1px solid var(--ui-light-shade);
    }
  }
  
}
