@import "@/index.scss";

.home-content-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    // background-color: red;

    .primary-background {
        // position: absolute;
        width: 100%;
        height: 1900px;
        margin: 0px;
        padding: 0px;
        background-image: url(../../../../assets/images/svg/primary_background.svg);
        background-position: center -20px;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: flex-start;
        justify-content: center;

        .header-section {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 70%;
            color: var(--ui-primary);
            z-index: 10;

            @include media-breakpoint-down(lg) {
                width: 90%;
            }

            .fs-logo-part {
                width: 100%;
                padding: 4vh 0px 0px 0px;

                img {
                    width: calc(100% / 6.5);

                    @include media-breakpoint-down(lg) {
                        width: auto;
                        height: 100px;
                    }
                }

                @include media-breakpoint-down(lg) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .header {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                width: 100%;
                margin-bottom: 5vh;

                .header-texts {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 30px;
                    width: 480px;
                    padding: 5vh 0px;

                    @include media-breakpoint-down(lg) {
                        width: 100%;
                        padding: 5vh 0px 0px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                    }

                    h1 {
                        font-size: 3.4rem;
                        font-weight: 800;

                        @include media-breakpoint-down(lg) {
                            font-size: 1.8rem;
                        }
                    }

                    small {
                        text-align: justify;
                        font-size: 16px;
                        font-weight: 400;
                    }

                    .MuiButton-containedPrimary {
                        margin-top: 25%;
                        width: 300px;
                        font-size: 13px;
                        text-transform: uppercase;
                        @include media-breakpoint-down(lg) {
                            margin-top: 0%;
                        }

                        @include media-breakpoint-down(md) {
                           width: 100%;
                        }
                    }
                }

                .header-image {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    padding-right: 8%;
                    flex: 1;

                    img {
                        width: 72%;
                    }

                    @include media-breakpoint-down(md) {
                        display: none;
                       
                    }
    
                    @include media-breakpoint-down(lg) {
                        display: none;
                    }
    
                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }

               
            }


            .fs-box {
                width: 100%;
                background-color: #ffffff;
                padding: 5vh 0px;
                border-radius: 25px;
                box-shadow: 0px 16px 69px #00000029;
                margin-bottom: 50px;
                z-index: -2;
            }

            .section {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                gap: 6vh;
                width: 100%;
                height: fit-content;

                .profession-header {
                    width: 70%;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    gap: 30px;

                    @include media-breakpoint-down(lg) {
                        width: 90%;
                    }

                    h5 {
                        font-size: 1.8rem;
                        font-weight: 700;
                        color: #000000;
                        text-align: center;

                        @include media-breakpoint-down(lg) {
                            font-size: 1.5rem;
                        }
                    }

                    .profession-description {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        gap: 30px;
                        color: var(--ui-secondary);

                        @include media-breakpoint-down(lg) {
                            font-size: 0.8rem;
                            gap: 13px;
                        }
                    }
                }

                .office-container {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 20px;
                    width: 90%;

                    // @include media-breakpoint-down(lg) {
                    //     flex-wrap: nowrap !important;
                    //  }

                    @include media-breakpoint-down(lg) {
                        flex-wrap: wrap;
                     }

                    .office-box {
                        width: calc((100% / 3) - 13.33px);
                        border-radius: 23px;
                        background-color: var(--ui-tertiary);
                        padding: 7vh 30px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: flex-start;
                        position: relative;

                        @include media-breakpoint-down(lg) {
                            width: 100%;
                         }
                        // transition: all 300ms ease-in-out;

                        &:before {
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0px;
                            left: 0px;
                            border-radius: 23px;
                            z-index: -1;
                            opacity: 0;
                            // background: linear-gradient(to bottom, #ffffff, #F5CB40);
                            transition: all 300ms ease-in-out;
                        }

                        &:hover {

                            // box-shadow: 10px 10px 0px rgba($color: #000000, $alpha: 1.0);
                            &:nth-child(1) {
                                &:before {
                                    opacity: 1;
                                    top: 12px;
                                    left: 12px;
                                    background: linear-gradient(to bottom, #ffffff, #F5CB40);
                                }
                            }

                            &:nth-child(2) {
                                &:before {
                                    opacity: 1;
                                    top: 12px;
                                    left: 12px;
                                    background: linear-gradient(to bottom, #ffffff, #F59440);
                                }
                            }

                            &:nth-child(3) {
                                &:before {
                                    opacity: 1;
                                    top: 12px;
                                    left: 12px;
                                    background: linear-gradient(to bottom, #ffffff, #F54943);
                                }
                            }
                        }

                        .office-image {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 150px;
                            height: 150px;
                            // border: 1px solid;
                            border-radius: 50%;
                            margin-bottom: 20px;

                            // &:nth-child(1){
                            //     background-color: #F5CB40;
                            // }

                            // &:nth-child(2){
                            //     background-color: #F59440;
                            // }

                            // &:nth-child(3){
                            //     background-color: #F54943;
                            // }

                            img{
                                margin-top: 8px;
                                margin-right: 3px;
                            }
                        }

                        h6 {
                            font-size: 1.5rem;
                            color: #000000;
                            margin-bottom: 20px;
                            text-align: center;
                        }

                        p {
                            color: var(--ui-secondary);
                            font-size: 0.90rem;
                            text-align: center;
                        }

                        ul {
                            list-style: none;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            gap: 8px;
                            margin-top: 50%;

                            @include media-breakpoint-down(lg) {
                                margin-top: 20px;
                                align-items: center;
                             }

                             @include media-breakpoint-down(md) {
                                font-size: 0.8rem;
                             }

                            li {
                                display: flex;
                                align-items: center;
                                gap: 20px;

                                .iconify {
                                    color: var(--ui-secondary);
                                }

                                span {
                                    font-weight: 700;
                                    color: #000000;
                                }
                            }
                        }
                    }
                }

                .partner-list {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 3.5vw;
                    width: 90%;
                    margin: 0px 0px 4% 0px;

                    img {
                        height: 60px;
                    }

                    @include media-breakpoint-down(md) {
                        flex-wrap: wrap;
                        gap: 20px;
                     }
                }
            }

            .profession-first {
                display: flex;
                align-items: center;
                gap: 50px;
                width: 90%;

                @include media-breakpoint-down(md) {
                   flex-wrap: wrap;
                   border-top: 2px solid var(--ui-tertiary);
                   padding: 15px 0px;
                   text-align: center;
                }

                .profession-image {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 45%;

                    // background-color: red;
                    img {
                        width: 100%;
                    }

                    @include media-breakpoint-down(md) {
                        width: 100%;
                     }
                }
            }

            .profession-second {
                display: flex;
                align-items: center;
                gap: 50px;
                width: 90%;

                @include media-breakpoint-down(md) {
                    flex-wrap: wrap;
                    border-top: 2px solid var(--ui-tertiary);
                    padding: 15px 0px;
                    text-align: center;
                    flex-direction: column-reverse;
                 }

                .profession-image {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 45%;

                    // background-color: red;
                    img {
                        width: 100%;
                    }

                    @include media-breakpoint-down(md) {
                        width: 100%;
                     }
                }
            }

            .profession-third {
                display: flex;
                align-items: center;
                gap: 50px;
                width: 90%;

                @include media-breakpoint-down(md) {
                    flex-wrap: wrap;
                    border-top: 2px solid var(--ui-tertiary);
                    padding: 15px 0px;
                    text-align: center;
                 }

                .profession-image {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 45%;

                    // background-color: red;
                    img {
                        width: 100%;
                    }

                    @include media-breakpoint-down(md) {
                        width: 100%;
                     }
                }
            }

            .profession-content {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 30px;
                flex: 1;

                @include media-breakpoint-down(md) {
                    align-items: center;
                 }

                h6 {
                    font-size: 1.5rem;
                    color: #000000;

                    @include media-breakpoint-down(lg) {
                        font-size: 1.2rem !important;
                    }
                }

                p {
                    color: var(--ui-secondary);
                    font-size: 0.90rem;

                    @include media-breakpoint-down(lg) {
                        font-size: 0.8rem;
                    }
                }

                ul {
                    list-style: none;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 8px;

                    @include media-breakpoint-down(md) {
                        align-items: center;
                     }
                    // background-color: yellow;

                    li {
                        display: flex;
                        align-items: center;
                        gap: 20px;

                        .iconify {
                            color: var(--ui-secondary);
                        }

                        span {
                            font-weight: 700;
                            color: #000000;
                        }

                        @include media-breakpoint-down(lg) {
                            font-size: 0.8rem !important;
                        }
                    }
                }

                .MuiButton-containedPrimary {
                    margin-top: 20px;
                    width: 300px;
                    // min-width: 260px;
                    font-size: 13px;
                    text-transform: uppercase;
                    @include media-breakpoint-down(lg) {
                        margin-top: 0%;
                    }

                    @include media-breakpoint-down(md) {
                       width: 100%;
                    }
                }
            }

            .copy-right{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--ui-secondary);

                .copy-right-heart{
                    color: red;
                }
            }
        }

    }

    .secondary-background {
        // position: absolute;
        width: 100%;
        height: 1080px;
        margin-top: 22%;
        padding: 0px;
        background-image: url(../../../../assets/images/svg/secondary_background.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        z-index: 1;
    }

    .tertiary-background {
        // position: absolute;
        width: 100%;
        height: 520px;
        margin-top: 26%;
        padding: 0px;
        background-image: url(../../../../assets/images/svg/tertiary_background.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        z-index: 2;
    }



}