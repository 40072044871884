@import "@/index.scss";

.fs-modal-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 40vw;
  border: none;

  @include media-breakpoint-up(lg) {
    max-width: 714px;
  }

  .fs-modal-body {
    width: 100%;
    padding: 30px 20px;
    background-color: #ffffff;

    @include media-breakpoint-down(sm) {
      padding: 10px 5px;
    }

    .select-container{
      margin: 0px 0px 10px;
      padding: 0px 10px;
      header{
        font-size: 1rem;
      }
      label{
        font-size: 0.86rem;
      }
    }

    .fs-modal-body-container {
      background-color: var(--ui-tertiary);
      padding: 10px 25px;
      border-radius: 10px;

      @include media-breakpoint-down(sm) {
        padding: 10px;
      }

      .offre-box {
        // border: none;

        .offre-tabs {
          // background-color: #ffffff;
          // box-shadow: 0px 5px 17px rgba($color: #000000, $alpha: 0.22);

          .MuiTabs-flexContainer {
            justify-content: center;
            gap: 20px;

            @include media-breakpoint-down(md) {
              gap: 0px;
            }

            button.MuiTab-textColorPrimary {
              padding: 10px 15px;
              font-weight: 500;
              text-transform: inherit;
              font-size: 0.9rem;

              @include media-breakpoint-down(sm) {
                width: fit-content;
              }

              &.Mui-selected {
                color: var(--ui-secondary-shade);
                font-weight: 700;
              }
            }
          }

          .MuiTabs-indicator {
            height: 2px;
            // bottom: -10px;
            background-color: var(--ui-secondary-shade);
          }
        }
      }

      .MuiTabPanel-root {
        padding: 30px 0px;

        .situation-body-container {
          width: 100%;

          .situation-button {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            gap: 10px;
            padding: 10px 0px;

            button {
              border: 1px solid transparent;
              background-color: #ffffff;
              font-size: 0.75rem;
              padding: 5px 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 5px;
              transition: all 200ms ease-in-out;
              box-shadow: 0px 5px 12px rgba($color: #000000, $alpha: 0.1);

              img {
                height: 1rem;
              }

              &.celibataire {
                color: #5dc2ef;

                &.active {
                  border-color: #5dc2ef;
                  transform: scale(1.08);
                }
              }
              &.marie {
                color: #ef7c5d;
                &.active {
                  border-color: #ef7c5d;
                  transform: scale(1.08);
                }
              }
              &.divorce {
                color: #d8bc26;
                &.active {
                  border-color: #d8bc26;
                  transform: scale(1.08);
                }
              }
              &.veuf {
                color: #000000;
                &.active {
                  border-color: #000000;
                  transform: scale(1.08);
                }
              }
            }
          }

          .MuiDivider-fullWidth {
            width: 100%;
            font-size: 0.75rem;
            padding: 10px;
            &::before {
              top: 0px;
            }
            &::after {
              top: 0px;
            }
          }
        }

        .document-body-container {
          width: 100%;
          padding: 15px;
          background-color: #ffffff;
          border-radius: 10px;

          .MuiTable-root {
            min-width: 200px !important;
            flex: 1;
            .MuiTableBody-root {
              background-color: transparent;

              .MuiTableRow-root {
                background-color: transparent;

                .MuiTableCell-sizeMedium {
                  padding: 6px 0px;
                  border: none;
                  background-color: transparent;
                  font-size: 0.8rem;
                  color: #070e3b;

                  &:nth-child(2n + 1) {
                    text-align: left;
                    font-weight: 200;
                    width: 50%;
                  }

                  &:nth-child(2n) {
                    text-align: left;
                    font-weight: 700;
                  }
                }
              }
            }
          }

          .documentAddForm {
            // width: 420px;
            border: 1px dashed var(--ui-secondary);
            border-radius: 5px;
            padding: 5px;
            
            @include media-breakpoint-down(md) {
              width: 100%;
            }

            .upload-input {
              height: 31px;
              background-color: #e0e7ff;
              border: 1px dashed #bfceff;
              font-size: 11px;
              text-transform: initial;
              color: #5aa0dc;

              img {
                position: absolute;
                height: 35px;
                right: 2px;
                top: -15px;
              }

              &.file-uploaded{
                background-color: rgba($color: #006D77, $alpha: 0.15);
                border: 1px dashed var(--ui-primary);
                text-align: left;
                color: #000000;
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                gap: 5px;
                height: fit-content;

                progress {
                  opacity: 0;
                }
                
                .progress-element {
                  width: 100%;
                  margin: 0 0 10px;
                }
                
                .progress-container {
                  position: relative;
                  background: #eee;
                  height: 7px;
                  border-radius: 6px;
                  overflow: hidden;
                }
                
                .progress-container::before {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 100%;
                  width: 0;
                  background: turquoise;
                }
                
                .progress-element--html .progress-container::before {
                  animation: progress-html 1s ease-in forwards;
                }
                
                .progress-label {
                  position: relative;
                }
                
                @property --num {
                  syntax: "<integer>";
                  initial-value: 0;
                  inherits: false;
                }
                
                .progress-label::after {
                  counter-reset: num var(--num);
                  content: counter(num) "%";
                  position: absolute;
                  top: 0;
                  right: 0;
                }
                
                .progress-element--html .progress-label::after {
                  animation: progress-text-html 1s ease-in forwards;
                }
                
                @keyframes progress-html {
                  to {
                    width: 100%;
                  }
                }
                
                @keyframes progress-text-html {
                  to {
                    --num: 100;
                  }
                }
              }
            }
          }

          .document-form-container {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 20px;
            width: 100%;

            @include media-breakpoint-down(md) {
              flex-direction: column;
            }

            @include media-breakpoint-down(sm) {
            }

            .document-form {
              width: 0px !important;
              overflow: hidden;
              display: none;
            }

           
          }
        }
      }

      .modify-button {
        display: flex;
        // width: 100%;
        align-items: center;
        justify-content: flex-end;
        padding: 10px 14px 0px 0px;
        gap: 20px;

        .MuiButton-containedPrimary {
          margin: 0px;
          margin-top: -8px;
          width: fit-content;
          border-radius: 0;
        }
        .MuiButton-containedTertiary {
          margin: 0px;
          margin-top: -8px;
          width: fit-content;
          border-radius: 0;
          color: var(--ui-secondary-shade);
        }
      }
    }
  }
}
