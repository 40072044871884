@import '@/index.scss';

.c-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px;
  height: 80px;
  // background-color: red;

  @include media-breakpoint-down(md){
    flex-direction: column;
    padding: 2px;
    gap: 20px;
    height: 40px;
    margin-bottom: 10px;
  }

  @include media-breakpoint-down(sm) {
  }

  .mobile-header {
    display: none;
    position: fixed;
    padding: 5px;
    padding-top: 10px;
    height: 52px;
    background-color: var(--ui-white);
    margin-top: -8px;
    border-bottom: 1px solid var(--ui-gray);

    @media only screen and (max-width: 1200px) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      background-color: var(--ui-white);
      z-index: 1000;
      padding-left: 10px;
      padding-right: 10px;
      // padding: 15px 10px;
      top: 0;
      height: 60px;
    }

    // @include media-breakpoint-down(md){
    //   // height: 40px;
    //   width: 100%;
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;
    //   gap: 10px;
    //   background-color: var(--ui-white);
    //   z-index: 1000;
    // }

    .fs-profil-header {
      .profil-dropdown {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
        padding: 1px;
        text-transform: capitalize;
        color: var(--ui-tertiary-shade);
  
        .id-photo{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          // border: 1px solid var(--ui-tertiary-shade);
          border-radius: 50%;
          overflow: hidden;
  
          img{
            width: 100%;
            object-fit: cover;
          }
        }
  
        .user-names{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          
          .name{
            font-size: 12px;
            font-weight: bold;
            // line-height: 1.5rem;
          }
  
          .statut{
            color: var(--ui-secondary);
            font-weight: 500;
          }
        }
      }
    }

    .logo-mini{
      height: 70%;
      width: auto;
      transform: translateY(2px);
    }

    .title-part{
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 0px;
  
      .title {
        font-size: var(--small-size);
        font-weight: 400;
        color: var(--ui-tertiary-shade);
        @include text-ellipsis (150px);
        b{
          font-weight: 800;
        }
      }
  
      .subtitle {
        font-size: xx-small;
        color: var(--ui-tertiary-shade);
        @include text-ellipsis (150px);
      }
    }

    .btn-moderation {
      margin-left: auto;
      height: 30px;
      width: 30px;
      min-width: unset;
      border-radius: 7px;
      padding: 0%;
    }

    .rounded-square-btn-head{
        height: 30px;
        width: 30px;
        border-radius: 7px;
        background: var(--ui-primary);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 200ms ease-in-out;

        &:hover {
          cursor: pointer;
          background: var(--ui-gray);
        }
    }

  }

  .logo-mini-md{
    height: 50px;
    width: auto;
    margin-right: 10px;
    display: none;
    
    @include media-breakpoint-only(md){
      display: block;
    }
  }

  .title-part{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;

    @media only screen and (max-width: 1200px) {
      display: none;
     }

    @include media-breakpoint-down(md){
      width: 100%;
      display: none;
    }
    

    .title {
      font-size: var(--title-size);
      font-weight: 400;
      color: var(--ui-medium);
      text-transform: uppercase;
     
      b{
        font-weight: 800;
        color: var(--ui-tertiary-shade);
      }

      @include media-breakpoint-down(xl) { 
        // @include text-ellipsis (200px);
      }

      @include media-breakpoint-down(lg) { 
        // @include text-ellipsis (250px);
      }

    }

    .subtitle {
      font-size: var(--normal-size);
      color: var(--ui-tertiary-shade);
      color: var(--ui-medium);
      text-transform: uppercase;

      @include media-breakpoint-down(xl) { 
        // @include text-ellipsis (200px);
      }

      @include media-breakpoint-down(lg) { 
        // @include text-ellipsis (250px);
      }
    }
  }

  .fs-profil{
   
    .profil-dropdown{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 15px;
      padding: 10px;
      text-transform: capitalize;
      color: var(--ui-tertiary-shade);

      @media only screen and (max-width: 1200px) {
        display: none; 
      }

      @include media-breakpoint-down(lg) { 
        display: none;
      }

      @include media-breakpoint-down(md) { 
        // display: none;
      }

      .id-photo{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        // border: 1px solid var(--ui-tertiary-shade);
        border-radius: 50%;
        overflow: hidden;

        img{
          width: 100%;
          object-fit: cover;
        }
      }

      .user-names{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        
        .name{
          font-size: 16px;
          font-weight: bold;
          line-height: 1.5rem;
        }

        .statut{
          color: var(--ui-secondary);
          font-weight: 500;
        }
      }
    }

  }

  .icon-header{
    margin-left: 10px;
    cursor: pointer;

    @include media-breakpoint-down(lg) { 
      display: none;
    }
  }

  .rounded-square-btn{
      height: 35px;
      width: 35px;
      border-radius: 10px;
      background: var(--ui-primary);
      margin-left: 20px;
      // display: flex;
      align-items: center;
      justify-content: center;
      transition: all 200ms ease-in-out;
      display: none;

      @include media-breakpoint-down(lg) { 
        display: flex;
      }

      @include media-breakpoint-down(md) { 
        // display: none;
      }

      &:hover {
        cursor: pointer;
        background: var(--ui-gray);
      }
  }
}
