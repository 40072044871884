@import "@/index.scss";


.offre-container{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    background-color: #ffffff;

    .offre-header-container{
        width: 100%;
        height: 35vh;
        background: transparent linear-gradient(90deg, #006D77 0%, #00373C 100%) 0% 0% no-repeat padding-box;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        color: #ffffff;

        .offre-header-content{
            padding-left: 15%;
            padding-top: 2%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 25px;

            img{
                height: 10vh;
            }

            .breadcrumbs{
                font-weight: 300;
            }

            .offre-header{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 15px;

                .offre-title{
                    font-size: 1.5rem;
                    font-weight: 700;
                }

                p{
                    font-size: 0.96rem;
                    font-weight: 400;
                }
            }
        }
        
        .offre-img{
            height: 100%;
            width: fit-content;
            padding: 0px 2%;

            img{
                width: 86%;
            }
        }
    }

    .offre-body{
        width: 100%;
        height: auto;
        padding: 0px 22%;
        margin-top: -32px;

        .offre-box{
            border: none;

            @include media-breakpoint-down(lg) {
                padding: 10px;
             }

            .offre-tabs{
                background-color: #ffffff;
                box-shadow: 0px 5px 17px rgba($color: #000000, $alpha: 0.22);

                .MuiTabs-flexContainer{
                    justify-content: center;
                    gap: 5%;

                    button.MuiTab-textColorPrimary{
                        padding: 2% 5%;
                        font-weight: 500;
                        text-transform: inherit;
                        font-size: 1rem;

                        &.Mui-selected{
                            color: var(--ui-secondary-shade);
                            font-weight: 700;
                        }
                    }
                }
    
                .MuiTabs-indicator{
                    height: 6px;
                    // bottom: -10px;
                    background-color: var(--ui-secondary-shade);
                }
            }
        }

        .MuiTabPanel-root{
            padding: 30px;

            .offre-body-container{
                position: relative;
                width: 100%;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 3%;

                .offre-item{
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: 15px;
                    width: calc((100% / 3 ) - 2%);
                    height: 550px;
                    background: transparent linear-gradient(180deg, #FFFFFF 0%, #E1FCFF 100%) 0% 0% no-repeat padding-box;
                    box-shadow: 0px 16px 69px #00000029;
                    border-radius: 20px;
                    opacity: 1;
                    padding: 2.5%;

                    img{
                        position: absolute;
                        bottom: 0px;
                        left: 0px;
                        width: 100%;
                    }

                    .offre-item-value{
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-start;
                        gap: 15px;
                        width: 100%;
                    }

                    h4.item-title{
                        font-size: 1.3rem;
                        font-weight: 800;
                        color: var(--ui-secondary-shade);
                    }

                    h5.item-title{
                        font-size: 1rem;
                        font-weight: 800;
                        color: #000000;
                    }

                    p{
                        font-size: 0.80rem;
                    }

                    h3.item-montant{
                        font-size: 2.2rem;
                        font-weight: 800;
                        color: var(--ui-primary);

                        sup{
                            font-size: 0.75rem;
                            font-weight: 400;
                            transform: translate(10px, 10px);
                        }
                    }

                    .MuiButton-containedPrimary{
                        padding: 0px;
                        width: 100%;
                        margin-top: 50%;
                        z-index: 1;
                    }

                    .icon-offre{
                        font-size: 1.5rem;
                        margin: 0px;
                        color: var(--ui-primary);
                    }
                    .icon-offre-not{
                        font-size: 2rem;
                        margin: 0px;
                        color: var(--ui-danger);
                    }
                    .icon-offre-yes{
                        font-size: 2rem;
                        margin: 0px;
                        color: var(--ui-primary);
                    }

                    .offre-detail{
                        position: absolute;
                        overflow: hidden;
                        width: 84%;
                        height: 91%;
                        transition: all 300ms ease-in-out;
                        z-index: 3;

                        ul{
                            list-style: none;
                            padding-left: 0;
                            li{
                                display: flex;
                                align-items: center;
                                gap: 10px;
                                font-size: 0.80rem;
                                margin-bottom: 20px;

                                &.li-offre-not {
                                color: var(--ui-danger);
                                }
                            }
                        }
                    }

                    &:nth-child(2):hover{
                        .offre-detail{
                            height: 0px;
                        }
                    }
                    &:nth-child(2)::before{
                        content: '';
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 100%;
                        height: 100%;
                        background-color: #FFFFFF;
                        border-radius: 20px;
                        transition: all 300ms ease-in-out;
                        z-index: 2;
                    }
                    &:nth-child(2):hover::before{
                        content: '';
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 100%;
                        height: 0px;
                        background-color: #FFFFFF;
                        border-radius: 20px;
                    }

                    &:nth-child(3):hover{
                        .offre-detail{
                            height: 0px;
                        }
                    }
                    &:nth-child(3)::before{
                        content: '';
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 100%;
                        height: 100%;
                        background-color: #FFFFFF;
                        border-radius: 20px;
                        transition: all 300ms ease-in-out;
                        z-index: 2;
                    }
                    &:nth-child(3):hover::before{
                        content: '';
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 100%;
                        height: 0px;
                        background-color: #FFFFFF;
                        border-radius: 20px;
                    }
                }
            }
        }
    }
}