@import "@/index.scss";

form.form-utilisateur {
  background-color: var(--ui-tertiary);
  margin-top: 20px;
  border-radius: 7px;

  .modify-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    padding: 20px;
    button {
      width: fit-content;
      border-radius: 0px;
    }
  }
}
