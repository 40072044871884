@import "@/index.scss";

.authentication-layout-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    //background-color: rgba(var(--primary-rgb),1);
    background-color: var(--ui-light);
    position: relative;
    overflow-x: hidden;

    .jDUPIr{
        max-width: none;
        padding: 0px;
        margin: 0px;
    }
    
    .form-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // padding-top: 10vh;
        margin: 0 auto;
        gap: 50px;
        position: relative;

        @include media-breakpoint-up(lg) {
            // background-image: url(../../../assets/images/svg/login_bg.svg);
            background-position: center -500px;
            background-size: cover;
            background-repeat: no-repeat;
        }

        @include media-breakpoint-down(lg) {
            // background-image: url(../../../assets/images/svg/login_bg.svg);
            background-position: center -500px;
            background-size: cover;
            background-repeat: no-repeat;
        }

        .logo-title {
            width: 100px;
            height: auto;
            @include media-breakpoint-down(lg) {
                width: 100px;
            }
            @include media-breakpoint-down(md) {
                width: 100px;
            }
            @include media-breakpoint-down(sm) {
                width: 100px;
            }
        }

    }
    .login-bg{
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translate(-50%, 0px);
        width: 110%;
        height: 60%;
        background-color: #ECECEC;
        border-bottom-right-radius: 50%;
        border-bottom-left-radius: 50%;
        z-index: 1;
    }
}