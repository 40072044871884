.user-details-container {
  padding-bottom: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .user-datail-control {
    width: fit-content;
    display: flex;
    flex-direction: column;

    .label {
      font-size: 0.8rem;
    }
    .value {
      font-size: 0.8rem;
      font-weight: 600;
      background-color: rgba($color: #000000, $alpha: 0.05);
      padding: 6px 10px;
      border-radius: 5px;
    }
  }
}
