@import "@/index.scss";

.l-profile {
  width: 100%;
  min-height: 100vh;
  position: relative;
  // background-color: red;
  background: url(../../../../assets/images/svg/layout_bg.svg);
  background-size: 48%;
  background-position: -13.1vw -18vh;
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .backdrop {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    // background: rgba(var(--ui-dark-rgb),0.5);
    width: 100%;
    height: 100%;
    z-index: 2;

    @include media-breakpoint-down(lg) {
      display: none;
      &.showed {
        display: block;
      }
    }
  }
  .fs-sidebar {
    width: 280px;
  }

  .fs-container {
    @include global-container();

    @include media-breakpoint-down(lg) {
      margin-top: 0px;
    }

    @include media-breakpoint-down(md) {
      justify-content: center;
    }

    @include media-breakpoint-down(sm) {
    }
  }

  .profile-content {
    padding: 0px 35px 0px 0px;
    // width: calc( 100% - 300px ) ;
    flex: 1;
    min-height: 100vh;
    color: var(--ui-tertiary-shade);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
    gap: 15px;

    @include media-breakpoint-down(lg) {
      margin-left: unset;
      padding: 5px;
      // width: 100%;
      flex: 1;
    }

    @include media-breakpoint-down(xl) {
      padding: 5px;
    }
  }

  @include media-breakpoint-down(lg) {
    display: block;
  }

  @include media-breakpoint-down(xl) {
    display: block;
  }
}

.setting-tabs {
  margin-top: 10px;
  border-bottom: 1px solid var(--ui-light-shade);
  .Mui-disabled {
    display: none;
  }
  .MuiTabs-hideScrollbar {
    .MuiTabs-flexContainer {
      .MuiButtonBase-root {
        text-transform: initial;
        &.Mui-selected {
          background-color: rgba($color: #006d77, $alpha: 0.7);
          color: var(--ui-white);
          font-weight: 600;
        }
      }
    }
    .MuiTabs-indicator {
      height: 3px;
    }
  }
}
