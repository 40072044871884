@import "@/index.scss";

.c-list-texts {
  .parametre-text-content {
    padding-top: 0px;

    .setting-tabs{
      button{
        text-transform: initial;
      }
    }

    .parametre-text-table-part {
      padding-top: 5px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      .parametre-text-table-header {
        width: 100%;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;

        b {
          color: var(--ui-tertiary-shade);
          font-size: 15px;
          font-weight: 800;
        }

        button {
          width: auto;
          font-size: 12px;
          height: 33px;
          text-transform: inherit;
          padding: 5px 20px;
        }

        @include media-breakpoint-down(md) {
          justify-content: center;
          margin-bottom: 20px;
          button {
            position: relative;
            width: 100%;
          }
        }
      }

      .parametre-text-table-body {
        position: relative;
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 18px;
        transition: all 200ms ease-in-out;
      }
    }
  }
}
