@import "@/index.scss";

.add-acteur {
  padding: 20px;
  height: fit-content;

  .actor-button {
    display: flex;
    justify-content: flex-end;
    // align-items: center;
    padding: 0px 13px 30px 13px;
    margin-top: 15px;
    // width: 100%;
    button {
      width: fit-content;
    }
  }

  .actor-table-conytainer {
    height: fit-content;
    min-height: 750px;
  }
}
