@import '@/index.scss';

.form-prospect {
    padding: 10px;
    .bs-label-form, .bs-input-custom {
        font-size: 12px;
        sup {
            color: var(--ui-danger);
            font-size: 17px;
            top: 0;
            // font-size: large;
        }
    }

    .btn-manager {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        gap: 20px;
        button {
            width: auto;
            text-transform: inherit;
            height: 33px;
        }
    }
}