@import '@/index.scss';

.form-parametre-text {
    @include forms-container();
}

.rw-wrapper{
    border: 1px dashed ;
    padding: 10px;
    border-radius: 5px;
    min-height: 400px;
}