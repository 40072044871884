.box-statut-container {
    background-color: var(--ui-primary);
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: var(--ui-white);
    border-radius: 5px;
    .icon {
        font-size: 40px;
        color: var(--ui-white);
    }

    span {
        font-weight: 200;
        font-size: 0.8rem;
    }

    b {
        font-size: 1.6rem;
    }
}