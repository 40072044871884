@import "@/index.scss";

.dossier-content {
  padding-top: 0px;

  .block-stat-part {
    margin: 20px 0;
  }
  .dossier-table-part {
    padding-top: 5px;
    .dossier-table-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      b {
        color: var(--ui-tertiary-shade);
        font-size: 15px;
        font-weight: 800;
      }

      button {
        width: auto;
        font-size: 12px;
        height: 33px;
        position: absolute;
        right: 0;
        top: 10px;
        text-transform: inherit;
        padding: 5px 20px;
      }

      @include media-breakpoint-down(md) {
        justify-content: center;
        margin-bottom: 20px;
        button {
          position: relative;
          width: 100%;
        }
      }
    }

    .dossier-table-body {
      width: 100%;
    }
  }
}
