.app-editor {
  position: relative;
  z-index: 0;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  padding-bottom: 1rem;

  .editor-container {
    flex: 1;
    min-width: calc((100% / 3) * 2);
  }

  .variables-text-container {
    min-width: 300px;
    max-width: 600px;
    width: calc((100% / 3) - 20px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    p {
      margin: 0px;
    }

    .app-actions {
      position: relative !important;
      padding: 0px !important;
      width: 100% !important;
      top: 0px !important;
      right: auto !important;
      max-height: 300px;
      overflow-y: scroll;

      h6 {
        font-size: 0.9rem;
        font-weight: 700;
        margin-bottom: 5px;
      }

      .accordion-container {
        margin: 0px;
        padding: 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;

        .accordion-item {
          padding: 0px 0px 0px 10px;
          width: 100% !important;
          box-shadow: none;
          border: 1px dashed;

          .accordion-sammary {
            height: 30px !important;
            font-size: 0.9rem;

            .MuiAccordionSummary-content {
              margin: 0;

              p.css-46s6p1-MuiTypography-root {
                font-size: 0.88rem;
                line-height: 1rem;
                text-transform: initial !important;
              }
            }
          }

          .puce-content {
            display: flex;
            flex-direction: column;

            small {
              font-size: 0.69rem;
            }
          }
        }
      }
    }
  }
}
