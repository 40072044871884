.c-choose-parameters-type {
  .administration-content {
    padding: 20px 0px;

    .administration-header {
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      span {
        font-size: 1.8rem;
        font-weight: 700;
      }
      p {
        padding-top: 30px;
      }
    }

    .administration-body {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 3.5%;
      padding: 30px 0px;

      .menu-item {
        // width: calc((100% / 6) - 2.9166%);
        width: 200px;
        aspect-ratio: 1;
        padding: 20px 10px 20px 10px;
        box-shadow: 0px 2px 50px #ededef;
        border: 2px solid transparent;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        cursor: pointer;
        transition: all 300ms ease-in-out;

        span {
          font-size: 0.98rem;
          font-weight: 700;
          text-align: center;
          color: var(--ui-tertiary-shade);
        }

        img {
          height: 80px;
        }

        .cogs-icon {
          font-size: 6rem;
          color: var(--ui-primary);
        }

        &:hover {
          border: 2px solid var(--ui-primary);
        }
      }
    }
  }
}
